import React, { useEffect, useCallback, useRef } from 'react';
import { PaymentsWidget, PaymentsWidgetProps } from '@wix/cashier-payments-widget/lazy';
import { useErrorMonitor, useFedopsLogger } from '@wix/yoshi-flow-editor';
import { PackagePickerInteractions } from '../../../../../types/PackagePickerFedops';
import { toError } from '../../../../../utils/errors';

export const MonitoredPaymentsWidget: React.FC<PaymentsWidgetProps> = ({ onFullLoad, onCrash, ...props }) => {
  const fedops = useFedopsLogger();
  const errorMonitor = useErrorMonitor();
  const isLoading = useRef(true);

  useEffect(() => {
    fedops.interactionStarted(PackagePickerInteractions.CashierWidgetLoaded);
    return () => {
      if (isLoading.current) {
        fedops.interactionEnded(PackagePickerInteractions.CashierWidgetLoaded);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cashierWidgetLoaded = useCallback(() => {
    isLoading.current = false;
    fedops.interactionEnded(PackagePickerInteractions.CashierWidgetLoaded);
    onFullLoad?.();
  }, [fedops, onFullLoad]);

  const captureError = useCallback(
    (error: Error | string) => {
      errorMonitor.captureException(toError(error), {
        tags: {
          interaction: PackagePickerInteractions.CashierWidgetLoaded,
        },
      });
      onCrash?.(error);
    },
    [onCrash, errorMonitor],
  );

  return <PaymentsWidget {...props} onCrash={captureError} onFullLoad={cashierWidgetLoaded} />;
};
